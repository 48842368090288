import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { FiMap } from "react-icons/fi";

import { Icon } from "leaflet";
import { Marker, Popup } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  addItems,
  removeItem,
  removeItems,
} from "../../lib/slices/cartSlice";
import { open } from "../../lib/slices/sidebarSlice";

import MuppiImage from "../../assets/images/muppi_image.png";
import BlueMarker from "../Map/images/outdoor_blue.png";
import RedMarker from "../Map/images/outdoor_red.png";
import GreenMarker from "../Map/images/outdoor_green.png";
import _ from "lodash";

import "./markerPopup.scss";

dayjs.extend(weekday);

function MarkerPopupOutdoor(props) {
  const { outdoor, onlyView } = props;
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.value);
  const outdoors = useSelector((state) => state.outdoor.value);
  const { startDate, endDate } = useSelector((state) => state.search);

  const markerBlueMarker = new Icon({
    iconUrl: BlueMarker,
    iconSize: [40, 45],
  });

  const markerRedMarker = new Icon({
    iconUrl: RedMarker,
    iconSize: [40, 45],
  });

  const makerGreenMarker = new Icon({
    iconUrl: GreenMarker,
    iconSize: [40, 45],
  });

  const handleAddCart = (outdoor, all = false) => {
    if (!all) {
      const newOutdoors = {
        ...outdoor,
        startDate: startDate
          ? dayjs(startDate).toJSON()
          : dayjs().weekday(3).toJSON(),
        endDate: endDate
          ? dayjs(endDate).toJSON()
          : dayjs().weekday(3).add(1, "week").toJSON(),
      };
      dispatch(addItem(newOutdoors));
    } else {
      let outdoorsNetwork = _.filter(
        outdoors,
        (pin) => pin.network === outdoor.network
      );
      outdoorsNetwork = _.map(outdoorsNetwork, (pin) => {
        return {
          ...pin,
          startDate: startDate
            ? dayjs(startDate).toJSON()
            : dayjs().weekday(3).toJSON(),
          endDate: endDate
            ? dayjs(endDate).toJSON()
            : dayjs().weekday(3).add(1, "week").toJSON(),
        };
      });
      dispatch(addItems(outdoorsNetwork));
    }
    dispatch(open());
  };
  const handleRemoveCart = (outdoor, all = false) => {
    if (!all) {
      dispatch(removeItem(outdoor));
    } else {
      dispatch(
        removeItems(
          _.filter(outdoors, (pin) => pin.network === outdoor.network)
        )
      );
    }
    dispatch(open());
  };

  const allNetworkInCart = () => {
    if (outdoor.network !== "") {
      const outdoorsFromNetwork = _.filter(
        outdoors,
        (pin) => pin.network === outdoor.network
      );
      const cartFromNetwork = _.filter(
        cart,
        (pin) => pin.network === outdoor.network
      );
      const diff = _.differenceBy(outdoorsFromNetwork, cartFromNetwork, "_id");
      if (_.isEmpty(diff)) {
        return true;
      }
    }
    return false;
  };
  console.log(outdoor);
  return (
    <Marker
      position={[
        outdoor.location.coordinates[0],
        outdoor.location.coordinates[1],
      ]}
      icon={
        cart.some((pin) => pin.serie === outdoor.serie)
          ? markerBlueMarker
          : outdoor.isFree
          ? makerGreenMarker
          : markerRedMarker
      }
    >
      <Popup>
        <div className="marker-popup">
          <div className="marker-popup__header">
            <div className="image">
              <img src={MuppiImage} alt="Muppi Imagem" />
            </div>
            <a
              href={`http://maps.google.com/maps?q=&layer=c&cbll=${outdoor.location.coordinates[0]},${outdoor.location.coordinates[1]}`}
              target="_blank"
              className="street_view"
              rel="noreferrer"
            >
              <div className="street_view_link">
                Street view <FiMap stroke="#0181BA" />
              </div>
            </a>
          </div>
          <div className="marker-popup__content">
            {outdoor.concelho && (
              <div className="marker-popup__content__item">
                <div className="title">Concelho: </div>
                <div className="value">{outdoor.concelho}</div>
              </div>
            )}
            {outdoor.network && (
              <div className="marker-popup__content__item">
                <div className="title">Rede: </div>
                <div className="value">{outdoor.network}</div>
              </div>
            )}
            <div className="marker-popup__content__item">
              <div className="title">Serie:</div>
              <div className="value">{outdoor.serie}</div>
            </div>
            <div className="marker-popup__content__availability">
              {outdoor.isFree ? (
                <div className="free">Livre para Utilização</div>
              ) : (
                <div className="busy">
                  Ocupado até {dayjs(outdoor.until).format("DD/MM/YYYY")}
                </div>
              )}
            </div>
            {!onlyView && (
              <div className="marker-popup__content__buttons">
                {cart.some((pin) => pin.serie === outdoor.serie) ? (
                  <button
                    onClick={() => {
                      handleRemoveCart(outdoor);
                    }}
                    className="border_button"
                  >
                    Remover Equipamento
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleAddCart(outdoor);
                    }}
                    className={
                      outdoor.network ? "border_button" : "normal_button"
                    }
                  >
                    Adicionar Equipamento
                  </button>
                )}
                {outdoor.network !== "" && (
                  <>
                    {!allNetworkInCart() && (
                      <button
                        onClick={() => {
                          handleAddCart(outdoor, true);
                        }}
                        className="normal_button"
                      >
                        Adicionar toda rede
                      </button>
                    )}
                    {allNetworkInCart() && (
                      <button
                        onClick={() => {
                          handleRemoveCart(outdoor, true);
                        }}
                        className="border_button"
                      >
                        Remover toda rede
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </Popup>
    </Marker>
  );
}

export default MarkerPopupOutdoor;
