import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import api from "../axios";

export const getOutdoors = createAsyncThunk(
  "outdoors/all",
  async (options = {}) => {
    const { params, hash } = options;
    const endpoint = hash ? `/order/${hash}` : "/map/outdoors";
    const response = await api({
      url: endpoint,
      method: "get",
      params,
    });
    if (response.statusText !== "OK") {
      throw new Error("Failed to fetch outdoors");
    }
    const data = await response.data;
    return data;
  }
);

export const outdoorsSlice = createSlice({
  name: "outdoors",
  initialState: {
    value: [],
    initialValue: [],
  },
  reducers: {
    addItems: (state, action) => {
      state.value = action.payload;
      if (_.isEmpty(state.initialValue)) {
        state.initialValue = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOutdoors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOutdoors.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
        if (_.isEmpty(state.initialValue)) {
          state.initialValue = action.payload;
        }
      })
      .addCase(getOutdoors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const { addItems, changeItem } = outdoorsSlice.actions;

export default outdoorsSlice.reducer;
